import React from 'react';
import { Link } from 'react-router-dom';

import Main from '../../main/Main';

const Home = () => (
    <Main>
        <article className="post" id="index">
            <header>
                <div className="title">
                    <h2><Link to="/">About this site</Link></h2>
                    <p>A beautiful, responsive, react app written with modern Javascript.</p>
                </div>
            </header>
            <p> Welcome to my website. Please feel free to read more <Link to="/about">about me</Link>,
                or you can check out my {' '}
                <Link to="/resume">resume</Link>, {' '}
                view <Link to="/stats">site statistics</Link>, {' '}
                or <Link to="/contact">contact</Link> me.
            </p>
        </article>
    </Main>
);

export default Home;
