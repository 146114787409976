const experience = [
    {
        company: 'Little Baby Ana 👶 🍼',
        position: 'Parent',
        link: '',
        daterange: 'June 2021 - Present',
        points: [
            'Who said it\'s not a full time job?'
        ],
    },
    {
        company: 'Paddy Power Betfair (Blip)',
        position: 'Senior Software Developer',
        link: 'https://blip.pt',
        daterange: 'February 2014 - Present',
        points: [
            'Development of mobile single page app',
            'Contribution to several projects in the Risk & Trading area building apps to manage betting risk',
            'AWS, Apache Flink, Apache Kafka, Spring Boot, RabbitMQ, Maven, Grade, Bash',
            'HTML, JavaScript, CSS, AngularJS, NodeJS, ReactJS, Redux, Nginx'
        ],
    },
    {
        company: 'Morrisons',
        position: 'Systems Analyst',
        link: 'https://morrisons.com',
        daterange: 'September 2012 - February 2014',
        points: [
            'Development, implementation and customisation of several modules of Oracle products such as ORMS (retail merchandise), OReSA (sales audit), OReIM (invoice matching)',
            'Integration with legacy systems',
            'Oracle Forms, Java, ProC, PL/SQL, Bash'
        ],
    },
    {
        company: 'Wipro Retail',
        position: 'Project Programmer',
        link: 'https://www.wipro.com/retail',
        daterange: 'August 2011 - September 2012',
        points: [
            'Development, implementation and customisation of several modules of Oracle products such as ORMS (retail merchandise) and OSIM (store inventory)',
            'Volume and performance testing',
            'Oracle Forms, Java, ProC, PL/SQL, Bash'
        ],
    },
    {
        company: 'OPT',
        position: 'Computer Programmer',
        link: 'https://www.opt.pt/en/opt-en',
        daterange: 'February 2011 - July 2011',
        points: [
            'Development of a real time routing planner algorithm',
            'Contributions to Move-me project (https://move-me.mobi)',
            'C#, NHibernate.'
        ],
    }
];

export default experience;
