const education = [
    {
        school: 'Faculty of Engineering - Oporto University',
        degree: 'M.S. Informatics and Computing Engineering',
        link: 'https://sigarra.up.pt/feup/en/CUR_GERAL.CUR_VIEW?pv_curso_id=742&pv_origem=CUR&pv_ano_lectivo=2010',
        year: 2011,
    }
];

export default education;
