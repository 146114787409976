const stats = {
    age: {
        label: 'Current age',
        value: 0,
    },
    countries: {
        label: 'Countries visited',
        value: 20,
        link: 'https://www.google.com/maps/d/embed?mid=1uwdPfWRl3wOJhj9CvwYOdk2khRTQRmVW',
    },
    location: {
        label: 'Current city',
        value: 'Oporto, Portugal',
    }
};

export default stats;
